import { Component, OnInit } from '@angular/core';
import { MenusService } from '../../Services/menus/menus.service';
import { MenucategoryService } from '../../Services/menucategory/menucategory.service';
import { Menus } from '../../Models/menus';
import { MenuCategory } from '../../Models/menucategory';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.scss']
})
export class MenusComponent implements OnInit {


  menus: Menus[] = [];
  categories: MenuCategory[] = [];


  constructor(private apiMenu: MenusService, private apiCategory: MenucategoryService) { }


  ngOnInit() {

    this.apiCategory.getMenuCategory().subscribe(resp => {
      this.categories = resp;
    }, err => {
    });
    
    this.apiMenu.getMenus().subscribe(res => {
      this.menus = res;
    }, err => {
    });
    

    


    this.filterSelection("all");

    var btnContainer = document.getElementById("filterContainer");
    var btns = btnContainer.getElementsByClassName("btn");
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("active");
        current[0].className = current[0].className.replace(" active", "");
        this.className += " active";
      });
    }
  }

  filterSelection(c) {
    var x, i;
    x = document.getElementsByClassName("menu-item");
    if (c == "all") c = "";
    for (i = 0; i < x.length; i++) {
      this.w3RemoveClass(x[i], "show");
      if (x[i].className.indexOf(c) > -1) this.w3AddClass(x[i], "show");
    }
  }
  w3RemoveClass(element, name) {
    var i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
    for (i = 0; i < arr2.length; i++) {
      while (arr1.indexOf(arr2[i]) > -1) {
        arr1.splice(arr1.indexOf(arr2[i]), 1);
      }
    }
    element.className = arr1.join(" ");
  }

  w3AddClass(element, name) {
    var i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
    for (i = 0; i < arr2.length; i++) {
      if (arr1.indexOf(arr2[i]) == -1) {
        element.className += " " + arr2[i];
      }
    }
  }

}
