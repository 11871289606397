import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Menekşe Bahçesi';

  mobileMenu(){
    let topmenu = document.getElementById("topmenu");

    if(topmenu.classList.value.indexOf("active") > -1 ){
      topmenu.classList.remove("active");
    }else{
      topmenu.classList.add("active");
    }





  }
}
