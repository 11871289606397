import { Component, OnInit } from '@angular/core';
import { GalleryService } from '../../Services/gallery/gallery.service'
import { Gallery } from '../../Models/gallery'
import { subscribeOn } from 'rxjs/operators';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-photogallery',
  templateUrl: './photogallery.component.html',
  styleUrls: ['./photogallery.component.scss']
})
export class PhotogalleryComponent implements OnInit {

  photos: Gallery[] = [];

  constructor(private api: GalleryService) { }

  ngOnInit() {
    this.api.getGalleryImages().subscribe(arg => { 
      this.photos = arg ;
      setTimeout(function(){ 
        $('.photo-gallery a').magnificPopup({
          type: 'image',
          gallery: {
            enabled: true,
            navigateByImgClick: true
          },
        });

       }, 100);


      



    });

    
  }
}
