import { Component, OnInit } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';
import Swal from 'sweetalert2';


declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit {
  locale = 'tr';
  locales = listLocales();
  minDate: Date;

  date: string;
  namesurname: string;
  phonenumber: string;
  personCount: string;


  constructor(private localeService: BsLocaleService) {
    this.minDate = new Date();
  }

  ngOnInit() {
    this.localeService.use(this.locale);
  }

  sendReservation(data) {
    $.ajax({
      type: "POST",
      url: "/mail/reservation.php",
      headers: {
        "Access-Control-Allow-Origin":"http://meneksebahcesi.com",
    },
      data: { "date": data.value.date, "namesurname": data.value.namesurname, "phonenumber": data.value.phonenumber, "personCount": data.value.personcount },
      success: function (response) {
        Swal.fire({
          title: 'Teşekkürler',
          text: 'Rezervasyon talebiniz alınmıştır. En kısa sürede size geri dönüş yapıyor olacağız.',
          type: 'success',
          confirmButtonText: 'Tamam'
        })
      },
      error:function(error){
        Swal.fire({
          title: 'Hata',
          text: 'Rezervasyon formunuz oluşturulamadı. Lütfen tekrar deneyin.',
          type: 'error',
          confirmButtonText: 'Tamam'
        })
      }
    });

  }

}
