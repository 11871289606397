import { Injectable } from '@angular/core';
import { Observable, of, throwError, from } from 'rxjs';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Posts } from '../../Models/instaPost';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';



const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const apiUrl = "https://api.instagram.com/v1/users/10672159019/media/recent/?access_token=10672159019.1677ed0.bba774acaa3c44f8b6076f70a05905c4";

@Injectable({
  providedIn: 'root'
})
export class InstagramService {

  constructor(private http: Http) { }
  

  getPosts() {
    return this.http.get(apiUrl).map((resp)=> {
      return resp.json();
    });
  }


}
