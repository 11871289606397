import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomepageComponent} from './Pages/homepage/homepage.component'
import {AboutComponent} from './Pages/about/about.component'
import {MenusComponent} from './Pages/menus/menus.component'
import {PhotogalleryComponent} from './Pages/photogallery/photogallery.component'
import {ContactComponent} from './Pages/contact/contact.component'


const routes: Routes = [
  {
    path: '',
    component: HomepageComponent   
  },
  {
    path: 'hakkimizda',
    component: AboutComponent   
  },
  {
    path: 'menulerimiz',
    component: MenusComponent,
  },
  {
    path: 'foto-galeri',
    component: PhotogalleryComponent,
  },
  {
    path: 'iletisim',
    component: ContactComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
