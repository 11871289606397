import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InstagramService } from '../../Services/instagram/instagram.service'
import { Posts } from '../../Models/instaPost';

@Component({
  selector: 'app-socialmedia',
  templateUrl: './socialmedia.component.html',
  styleUrls: ['./socialmedia.component.scss']
})
export class SocialmediaComponent implements OnInit {

  post: any;

  customOptions: any = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    items: 6,
    nav: false,
    margin: 15,
    responsive: {
      0: {
        items: 1
      },
      480: {
        items: 4
      },
      520: {
        items: 2
      },
      768: {
        items: 4
      },
      1200:{
        items:5
      }      
    }
  }
  constructor(private instagramService: InstagramService, private router: Router) { }
  ngOnInit() {
    this.instagramService.getPosts()
      .subscribe(res => {
        this.post = res.data;
      }, err => {
      });
  }

}
