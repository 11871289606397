import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-homeabout',
  templateUrl: './homeabout.component.html',
  styleUrls: ['./homeabout.component.scss']
})
export class HomeaboutComponent implements OnInit {



  
  constructor() { }
  ngOnInit() {
    

  }

}
