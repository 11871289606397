import { Injectable } from '@angular/core';
import { Observable, of, throwError, from } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import {Slides} from '../../Models/slides';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';

const apiUrl = "../datas/slider.json";

@Injectable({
  providedIn: 'root'
})
export class SliderService {
  constructor(private http:HttpClient) { }
 
  getSlidess():Observable<Slides[]>{
    return this.http.get<Slides[]>(apiUrl)
  }

}
