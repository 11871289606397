import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {RouterModule} from '@angular/router';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReservationComponent } from './Components/reservation/reservation.component';
import { HomeaboutComponent } from './Components/homeabout/homeabout.component';
import { HomepageComponent } from './Pages/homepage/homepage.component';
import { AboutComponent } from './Pages/about/about.component';
import { MenusComponent } from './Pages/menus/menus.component';
import { PhotogalleryComponent } from './Pages/photogallery/photogallery.component';
import { ContactComponent } from './Pages/contact/contact.component';
import { SocialmediaComponent } from './Components/socialmedia/socialmedia.component';
import { LocationmapComponent } from './Components/locationmap/locationmap.component';
import { HomesliderComponent } from './Components/homeslider/homeslider.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { AgmCoreModule } from '@agm/core';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { InstagramService } from './Services/instagram/instagram.service';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { trLocale } from 'ngx-bootstrap/locale';
defineLocale('tr', trLocale); 
import {NgxMaskModule} from 'ngx-mask'




@NgModule({
  declarations: [
    AppComponent,
    ReservationComponent,
    HomeaboutComponent,
    HomepageComponent,
    AboutComponent,
    MenusComponent,
    PhotogalleryComponent,
    ContactComponent,
    SocialmediaComponent,
    LocationmapComponent,
    HomesliderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    AngularFontAwesomeModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBeCRhmQUYUCptTVImvrqCv4LC4vzDyWng'
    }),
    HttpClientModule,
    HttpModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    NgxMaskModule.forRoot(),
    RouterModule
  ],
  providers: [InstagramService],
  bootstrap: [AppComponent]
})
export class AppModule { }
