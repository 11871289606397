import { Injectable } from '@angular/core';
import { Observable, of, throwError, from } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import {MenuCategory} from '../../Models/menucategory';

const apiUrl = "../datas/menu-category.json";


@Injectable({
  providedIn: 'root'
})
export class MenucategoryService {

  constructor(private http:HttpClient) { }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }

  getMenuCategory(): Observable<MenuCategory[]> {
    return this.http.get<MenuCategory[]>(apiUrl)
      .pipe(
        tap(),
        catchError(this.handleError('getMenuCategory', []))
      );
  }







}
