import { Component, OnInit } from '@angular/core';
import { SliderService } from '../../Services/slider/slider.service';
import { Slides } from '../../Models/slides';


@Component({
  selector: 'app-homeslider',
  templateUrl: './homeslider.component.html',
  styleUrls: ['./homeslider.component.scss']
})
export class HomesliderComponent implements OnInit {
  homeslides: any[];

  customOptions: any = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    items: 1,
    nav: true
  }
  constructor(private api: SliderService) { }
  ngOnInit() {
    this.api.getSlidess()
      .subscribe(
        data => {
          this.homeslides = data;
        });
  }
}
