import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  namesurname: string;
  phonenumber: string;
  email: string;
  message: string;

  constructor() { }

  ngOnInit() {
  }

  sendContact() {
    $.ajax({
      type: "POST",
      url: "/mail/contact.php",
      headers: {
        "Access-Control-Allow-Origin":"http://meneksebahcesi.com",
    },
      data: { "namesurname": this.namesurname, "phonenumber": this.phonenumber, "email": this.email, "message": this.message },
      success: function (response) {
        Swal.fire({
          title: 'Teşekkürler',
          text: 'Formunuz başarıyla gönderildi.',
          type: 'success',
          confirmButtonText: 'Tamam'
        })
      },
      error:function(error){
        Swal.fire({
          title: 'Hata!',
          text: 'Formunuz gönderilirken bir hata meydana geldi. Lütfen tekrar deneyin.',
          type: 'error',
          confirmButtonText: 'Tamam'
        })
      }
    });

  }

}
